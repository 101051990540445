import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const About = () => {

	return (
  	<Layout>
        <Seo title="サイトのご利用に関して" />
        <div className="main">
            <h1>本サイトの<br className="isShown"/>ご利用に関して</h1>

            <section className="contents">
                <p className="discription">当サイトを閲覧いただきありがとうございます。</p>
                <p className="discription">当サイトでは管理人の創作物を展示しております。</p>
            </section>

            <section className="contents">
                <h2 className="wider">プライバシー<br className="isShown"/>ポリシー</h2>
                <h3>Cookieの利用について</h3>
                <p>
                    Cookieは、当サイトや他サイトへのアクセスに関する情報が含まれており、多くのサイトで利用者に有益な機能を提供する目的で使用されています。Cookieには、サイト利用者の個人情報（氏名、住所、メールアドレス、電話番号）は一切含まれません。
                    当サイトは、第三者配信事業者（Google AdSense、Amazonアソシエイト、楽天アフィリエイト、A8.net、バリューコマース）がCookie を使用して、サイト利用者が当サイトや他のサイトに過去にアクセスした際の情報に基づいて広告を配信します。
                    Google が広告 Cookie を使用することにより、サイト利用者が当サイトや他のサイトにアクセスした際の情報に基づいて、Google やそのパートナーは適切な広告をサイト利用者に対して表示します。
                    広告設定でパーソナライズ広告を無効にすることができます。無効にする方法についてはこちらを参照してください。
                </p>
                <h3>個人情報の利用目的</h3>
                <p>
                    当サイトではお問い合わせの際名前やメールアドレス等の個人情報を入力いただく場合がございます。
                    取得した個人情報はお問い合わせに対する回答や必要な情報を
                    電子メールなどでご連絡をする場合に利用させていただくものであり、
                    これらの目的以外では利用いたしません。
                </p>
            </section>

            <section  className="contents">
                <h2>アクセス解析ツールについて</h2>
                <p>
                    当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
                    このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
                    このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
                    この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
                    この規約に関して、詳しくは<a href="http://www.google.com/analytics/terms/jp.html" rel="noopener noreferrer" target="_blank">こちら</a>、または<a href="https://www.google.com/intl/ja/policies/privacy/partners/" rel="noopener noreferrer" target="_blank">こちら</a>をクリックしてください。ブラウザ用の Google Analyticsオプトアウトアドオンの利用については<a href="https://tools.google.com/dlpage/gaoptout/" rel="noopener noreferrer" target="_blank">こちら</a>を参照してください。
                </p>
            </section>

            <section  className="contents">
                <h2>掲載されている広告について</h2>
                <p>
                    当サイトでは、第三者配信の広告サービス（<a href="http://www.google.com/adsense/start/" rel="noopener noreferrer" target="_blank">Googleアドセンス</a>、<a href="http://www.a8.net/" rel="noopener noreferrer" target="_blank">A8.net</a>、<a href="https://affiliate.amazon.co.jp/" rel="noopener noreferrer" target="_blank">Amazonアソシエイト</a>、<a href="https://www.valuecommerce.ne.jp/" rel="noopener noreferrer" target="_blank">バリューコマース</a>,<a href="https://affiliate.rakuten.co.jp" rel="noopener noreferrer" target="_blank">楽天アフィリエイト</a>）を利用しています。
                    このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。
                    またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、<a href="http://www.google.co.jp/policies/technologies/ads/" rel="noopener noreferrer" target="_blank">こちら</a>をクリックしてください。
                </p>
            </section>

            <section className="contents">
                <h2>免責事項</h2>
                <p>
                    当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
                    また当サイトのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。
                    当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
                </p>
            </section>

            <section className="contents">
                <h2>著作権について</h2>
                <p>
                    当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。
                    当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。
                </p>
            </section>

            <section className="contents">
                <h2>問い合わせ</h2>
                <p>
                    コンタクトフォームを作成中です。<br/>
                    しばらくの間、当サイトのブログに設置してある問い合わせフォームをご利用ください。
                </p>
                <a className="button" href="https://blog.ushiya.net/contactform/">コンタクトフォーム</a>
            </section>
        </div>
  	</Layout>
	)
}

export default About
